<template>
  <div class="mx-5 my-5">
    <v-alert border="top" color="green lighten-2" dark>
      المعلومات الشخصية
    </v-alert>
    <v-row class="mt-2">
      <v-col :cols="6" :md="6" :sm="12">
        <div
          :class="{
            'has-error': errors.has(`addEditValidation.name`),
          }"
        >
          <label>اسمك</label>
          <v-text-field
            class="d-block my-2"
            type="text"
            name="name"
            hide-details
            dense
            outlined
            v-model="obj.name"
            v-validate="'required'"
            data-vv-scope="addEditValidation"
            data-vv-as="اسمك"
          ></v-text-field>
        </div>
        <div class="help-block" v-if="errors.has(`addEditValidation.name`)">
          {{ errors.first(`addEditValidation.name`) }}
        </div>
      </v-col>

      <v-col :cols="6" :md="6" :sm="12">
        <div
          :class="{
            'has-error': errors.has(`addEditValidation.email`),
          }"
        >
          <label>البريد الإلكتروني</label>
          <v-text-field
            class="d-block my-2"
            type="text"
            name="email"
            v-validate="'required'"
            data-vv-scope="addEditValidation"
            :data-vv-as="$t('Email')"
            hide-details
            dense
            outlined
            v-model="obj.email"
          ></v-text-field>
        </div>
        <div class="help-block" v-if="errors.has(`addEditValidation.email`)">
          {{ errors.first(`addEditValidation.email`) }}
        </div>
      </v-col>

      <v-col :cols="6" :md="6" :sm="12">
        <div
          :class="{
            'has-error': errors.has(`addEditValidation.phone_number`),
          }"
        >
          <label>رقم الجوال الأساسي</label>
          <v-text-field
            class="d-block my-2"
            type="text"
            name="phone_number"
            hide-details
            dense
            outlined
            v-validate="'required'"
            data-vv-scope="addEditValidation"
            data-vv-as="رقم الجوال الأساسي"
            v-model="obj.phone_number"
          ></v-text-field>
        </div>
        <div class="help-block" v-if="errors.has(`addEditValidation.phone_number`)">
          {{ errors.first(`addEditValidation.phone_number`) }}
        </div>
      </v-col>

      <v-col :cols="6" :md="6" :sm="12">
        <div
          :class="{
            'has-error': errors.has(`addEditValidation.birth_date`),
          }"
        >
          <label>تاريخ الميلاد</label>
          <el-date-picker
            style="width: 100%"
            v-model="obj.birth_date"
            type="date"
            v-validate="'required'"
            data-vv-scope="addEditValidation"
            :data-vv-as="$t('BirthDate')"
            class="d-block my-2"
            name="birth_date"
            hide-details
            dense
            outlined
          >
          </el-date-picker>
        </div>
        <div
          class="help-block"
          v-if="errors.has(`addEditValidation.birth_date`)"
        >
          {{ errors.first(`addEditValidation.birth_date`) }}
        </div>
      </v-col>
    </v-row>

    <v-alert border="top" color="blue lighten-2" dark>
      معلومات الاشتراك
    </v-alert>

    <v-row class="mt-2">
      <v-col :cols="3">
        <div>
          <label>خيارات تجديد الاشتراك</label>
          <v-checkbox style="font-size:22px;" type="checkbox" v-model="obj.automatic_renewal" />
          <!-- <v-autocomplete
            class="d-block my-2"
            name="package_id"
            data-vv-scope="addEditValidation"
            v-validate="''"
            :data-vv-as="'خيارات تجديد الاشتراك'"
            hide-details
            dense
            outlined
            v-model="obj.subscription_options"
            item-value="id"
            item-text="name"
            :items="renewelList"
            clearable
          ></v-autocomplete> -->
        </div>
      </v-col>
      <v-col md="3">
        <p class="mt-5 pt-2">
          تتوفر هذه الميزة عندما يكون <br />رصيد محفظتك كافيا
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="py-3" :cols="3">
        <v-btn
          @click="updateData"
          class="mx-5 my-5"
          style="
            position: absolute;
            left: 5px;
            background: #107560 !important;
            color: #fff;
            border-radius: 8px;
            font-weight: bold;
          "
          color="success"
          dark
        >
          تحديث البيانات
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  data() {
    return {
      obj: {
        name: "",
        email: "",
        phone_number: "",
        birth_date: "",
        // subscription_options: "",
        automatic_renewal: false,
      },
      renewelList: [
        {
          id: 1,
          name: "التجديد التلقائي",
        },
      ],
    };
  },
  methods: {
    getData() {
      this.$store.dispatch(`customers/getSettingProfile`).then((res) => {
        this.obj = res.data;
          this.$validator.reset();
      });
    },
    updateData() {
       this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");

         let sendData = JSON.parse(JSON.stringify(this.obj));
         if (sendData.birth_date)
          sendData.birth_date = moment(sendData.birth_date).format(
            "YYYY-MM-DD"
          );

           this.$store
          .dispatch(`customers/updateSettingProfile`, sendData)
          .then((res) => {
            this.notifySuccess("تحديث المعلومات الشخصية", res);
            let nameUp= res.data.name;
            let customer= JSON.parse(localStorage.getItem('customer'));
            customer.name = nameUp;
            this.$root.$children[0].$children[0].$data.user.name= nameUp;
            localStorage.setItem('customer', JSON.stringify(customer));

            this.getData();
          });
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style>
#walletTable {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#walletTable th {
  background: #dfeff1 !important;
  color: #000;
}
#walletTable td,
#walletTable th {
  border: 1px solid #dddddd;
  text-align: right;
  padding: 8px;
}
#walletTable td {
  color: brown;
}
</style>